

















import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';

@Component({})
export default class NotFound extends Vue {
  get isLogin(): boolean {
    return !!vxm.user.token;
  }

  get isFanLayout() {
    return this.isLogin ? vxm.user.data.role !== 'performer' : true;
  }

  get homePath(): string {
    return this.isFanLayout ? '/fan/home' : '/login';
  }

  checkRedirect(link: string) {
    if (!this.isLogin) {
      this.$router.push('login');
    } else if (this.$route.path !== link) {
      this.$router.push(link);
    }
  }
}
